import styled from 'styled-components';
import CardStake from './components/CardStake';

export interface IStakeItem {
  type: 'Token' | 'LP';
  tokenName: string;
  earnToken: {
    name: string;
  };
}

const StakeList: IStakeItem[] = [
  {
    type: 'Token',
    tokenName: 'TOKEN A',
    earnToken: {
      name: 'TOKEN A',
    },
  },
  {
    type: 'LP',
    tokenName: 'TOKEN A',
    earnToken: {
      name: 'TOKEN A',
    },
  },
];

const ProCloudStake = () => {
  return (
    <ProCloudStakeWrapper>
      <div className="tvl-panel">CLOUD PROJECT TVL $32,312</div>
      <div className="panel-stake">
        {StakeList.map((item, index) => (
          <CardStake key={index} stakeItem={item} />
        ))}
      </div>
    </ProCloudStakeWrapper>
  );
};

export default ProCloudStake;

const ProCloudStakeWrapper = styled.div`
  background: #d9a4ad;
  width: 80%;
  margin: 10px auto;
  padding: 30px 12px;
  .tvl-panel {
    background: burlywood;
    width: 92%;
    margin: 30px auto;
    padding: 20px 40px;
    font-size: 24px;
    color: #212121;
    border-radius: 12px;
    text-align: center;
  }
  .panel-stake {
    width: 100%;
    padding: 40px 30px;
    display: flex;
    flex-wrap: wrap;
  }
`;
