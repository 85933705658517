import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import { usePrevious } from 'ahooks';
import qs from 'query-string';
import { BigNumber, constants } from 'ethers';
import config from '@/config/config';
import { parseEther, parseUnits } from '@ethersproject/units';
import Header from './components/Header/Header';
import LeftNavigator from './components/LeftNavigator';
import { flattenedRoutes } from './menus';
import VaultInfoContext, { initialVaultInfo } from './components/MyVault/context';
import { getTVL, getVaultInfo, VaultInfo } from './components/MyVault/server';
import reportRefAddress from './servers/reportInvitation';
import Footer from './components/Footer';
import styles from './style.module.scss';
import ScrollToTop from './components/ScrollToTop';
import { ZERO_ADDRESS } from './constants';
import DuetPriceMobile from './components/DuetPriceMobile';
import BuyCoin from './components/BuyCoin';
import { allDAssetsStrategyList, dAssetsInnovationStrategyList, dAssetsStrategyList } from './config/allLpTokens';
import { IContractContainer, injectContractERC20, injectContractMintVault, injectContractReader } from './contracts';
import { useWeb3 } from './web3';
import EbCakeBanner from './components/EbCakeBanner';
import BreadCrumb from './components/BreadCrumb';
import { getDusdMarketCap } from './utils/getDusdMarketCap';
import commifyBigNumber from './utils/commify';
import Banner from './components/Banner';
import ProCloud from './pages/ProCloud';
import ProCloudStake from './pages/ProCloudStake';

const AppInner: React.FC = () => {
  const { account, contractContainer } = useWeb3();
  const [vaultInfo, setVaultInfo] = useState<VaultInfo>(initialVaultInfo);
  const [refreshVaultInfoCount, setRefreshVaultInfoCount] = useState(0);

  const hideLeftNavigation = ['/duet-pro-cloud', '/duet-pro-cloud-stake'].includes(window.location.pathname);

  const previousAccount = usePrevious(account);

  // 如果切换 account 则刷新页面
  useEffect(() => {
    // 第一次连接钱包刷新页
    if (previousAccount && account && previousAccount !== account) {
      window.location.reload();
    }
  }, [previousAccount, account]);

  const refreshVaultInfo = useCallback(() => {
    setRefreshVaultInfoCount(refreshVaultInfoCount + 1);
  }, [refreshVaultInfoCount, setRefreshVaultInfoCount]);

  const context = useMemo(
    () => ({
      vaultInfo,
      refreshVaultInfo,
    }),
    [vaultInfo, refreshVaultInfo],
  );

  // 确定邀约关系
  useEffect(() => {
    if (!account) return;
    const { search } = window.location;
    if (!search) return;
    const params = qs.parse(search);
    if (params.address && typeof params.address === 'string') {
      reportRefAddress(params.address, account);
    }
  }, [account]);

  const getStableCoinMinterValue = async (contractContainer: IContractContainer) => {
    const busdContract = injectContractERC20(contractContainer, config.BUSD);
    const usdcContract = injectContractERC20(contractContainer, config.USDC);
    const [busdAmount, usdcAmount]: [BigNumber, BigNumber] = await Promise.all([
      busdContract.balanceOf(config.BUSD_DUSD),
      usdcContract.balanceOf(config.USDC_DUSD),
    ]);
    return busdAmount.add(usdcAmount).div(1e10);
  };

  const getUnLiquidationAmount = async (vaults: string[]) => {
    const vaultContracts = vaults.map(vault => injectContractMintVault(contractContainer!, vault));
    const underlyingArr: string[] = await Promise.all(vaultContracts.map(contract => contract.underlying()));
    const erc20Contracts = underlyingArr.map(underlying => injectContractERC20(contractContainer!, underlying));
    const underlyingAmount: BigNumber[] = await Promise.all(
      erc20Contracts.map((contract, index) => contract.balanceOf(vaults[index])),
    );

    return underlyingAmount;
  };

  const getDAssets = async () => {
    if (!contractContainer) return null;
    const vaults: string[] = allDAssetsStrategyList.map(each => each.vaultAddress);
    const contract = injectContractReader(contractContainer);
    const [dTokenVaultRes, stableCoinMinterValue, underlyingAmount]: [
      [BigNumber, BigNumber, BigNumber, BigNumber][],
      BigNumber,
      BigNumber[],
    ] = await Promise.all([
      contract.getDTokenVaultPrice(vaults, ZERO_ADDRESS, false),
      getStableCoinMinterValue(contractContainer),
      getUnLiquidationAmount(allDAssetsStrategyList.map(each => each.vaultAddress)),
    ]);

    const unLiquidationValue = underlyingAmount.reduce(
      (a, c, i) => a.add(dTokenVaultRes[1][i].mul(underlyingAmount[i]).div(parseUnits('1', 18))),
      constants.Zero,
    );

    const totalMarketCap: BigNumber = dTokenVaultRes[3]
      .reduce((a, c) => a.add(c), constants.Zero)
      .sub(stableCoinMinterValue)
      .sub(unLiquidationValue);

    return totalMarketCap;
  };

  useEffect(() => {
    if (!contractContainer) return;
    (async () => {
      let infoPromise = Promise.resolve(initialVaultInfo);
      if (account) {
        infoPromise = getVaultInfo(account, contractContainer);
      }
      const TVLPromise = getTVL(contractContainer);

      const [info, TVL, dAssets] = await Promise.all([infoPromise, TVLPromise, getDAssets()]);

      setVaultInfo({
        ...info,
        TVL: TVL.totalTVL,
        ebCakeTVL: TVL.ebCakeTVL,
        dAssets,
      });
    })();
  }, [refreshVaultInfoCount, account, contractContainer]);

  return (
    <section className={styles.AppWrapper} style={hideLeftNavigation ? { background: 'black' } : {}}>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        {/* <Banner /> */}
        <EbCakeBanner />
        <BreadCrumb />
        <section className={styles.BodyWrapper}>
          {!hideLeftNavigation && <LeftNavigator />}
          <VaultInfoContext.Provider value={context}>
            <section className={styles.Container} style={hideLeftNavigation ? { padding: 0 } : {}}>
              <Routes>
                <Route path="/" element={<Navigate to="/deposit-list" />} />
                <Route path="/duet-pro-cloud" element={<ProCloud />} />
                <Route path="/duet-pro-cloud-Stake" element={<ProCloudStake />} />
                {flattenedRoutes.map(route => (
                  <Route key={route.route} path={route.route} element={<route.component />} />
                ))}
              </Routes>
              <Footer />
              <DuetPriceMobile />
            </section>
          </VaultInfoContext.Provider>
        </section>
        <BuyCoin />
      </BrowserRouter>
    </section>
  );
};

export default AppInner;
