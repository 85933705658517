import commifyBigNumber from '@/utils/commify';
import { useWeb3 } from '@/web3';
import { useWeb3React } from '@web3-react/core';
import { Contract, ethers } from 'ethers';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IStakeItem } from '..';
import DEMOIMG from '../../../assets/duet-logo-light.png';
import { StakeAbi } from '../abis/StakeAbi';
import { TOKENA_STAKE_ADDRESS } from '../config';
import StakeModal from './StakeModal';
import WithdrawModal from './WithdrawModal';

export interface CardStakeProps {
  stakeItem: IStakeItem;
}

const CardStake = memo<CardStakeProps>(props => {
  const { stakeItem } = props;

  const [stakeModalVisible, setStakeModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [stakedAmount, setStakedAmount] = useState(ethers.constants.Zero);
  const [earnedAmount, setEarnedAmount] = useState(ethers.constants.Zero);
  const [refreshCount, setRefreshCount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(ethers.constants.Zero);
  const [apr, setApr] = useState('');

  const refresh = () => {
    setRefreshCount(refreshCount + 1);
  };

  const { account } = useWeb3();
  const { provider } = useWeb3React();

  useEffect(() => {
    (async () => {
      const contract = new Contract(TOKENA_STAKE_ADDRESS, StakeAbi, provider);
      const theTotalSupply = await contract._totalSupply();
      setTotalSupply(theTotalSupply);
      const rate = await contract.rewardRate();
      // 1 / totalSupply * rate * 86400 * 365
      const theApr = rate.mul(86400 * 365).div(theTotalSupply);
      setApr(theApr / 100 + '%');
      console.log('apr', +apr);
    })();
  }, [refreshCount]);

  useEffect(() => {
    if (!account) return;
    (async () => {
      const contract = new Contract(TOKENA_STAKE_ADDRESS, StakeAbi, provider);
      const stakeAmount = await contract._balances(account);
      setStakedAmount(stakeAmount);
    })();

    (async () => {
      const contract = new Contract(TOKENA_STAKE_ADDRESS, StakeAbi, provider);
      const earned = await contract.earned(account);
      setEarnedAmount(earned);
    })();
  }, [account, refreshCount]);

  const withdrawAll = async () => {
    const signer = provider?.getSigner();
    const contract = new Contract(TOKENA_STAKE_ADDRESS, StakeAbi, signer);
    const tx = await contract.withdraw(stakedAmount);
    await tx.wait();
    refresh();
  };

  const claim = async () => {
    const signer = provider?.getSigner();
    const contract = new Contract(TOKENA_STAKE_ADDRESS, StakeAbi, signer);
    const tx = await contract.getReward();
    await tx.wait();
    refresh();
  };

  return (
    <>
      <CardStakeView>
        <div className="info-row">
          <div className="logo-wrapper">
            <img src={DEMOIMG} alt="" className="logo" />
          </div>
          <p className="token-name">{stakeItem.tokenName}</p>
        </div>
        <div className="profit-row">
          <div className="profit-row-item">
            <p className="label">APR</p>
            <p className="value">{apr}</p>
          </div>
          <div className="profit-row-item">
            <p className="label">TVL</p>
            <p className="value">${commifyBigNumber(totalSupply)}</p>
          </div>
        </div>
        <div className="earned-row">
          <div className="left-panel">
            <span className="label-title">{stakeItem.earnToken.name} Earned</span>
            <span className="value">{commifyBigNumber(earnedAmount)}</span>
          </div>
          <div className="button" onClick={claim}>
            Claim
          </div>
        </div>
        <div className="stake-token">
          <div className="left-panel">
            <span className="label-title">{stakeItem.tokenName} Staked</span>
            <span className="value">{commifyBigNumber(stakedAmount)}</span>
          </div>
          <div className="button stake-button" onClick={() => setStakeModalVisible(true)}>
            Stake
          </div>
        </div>
        <div className="withdraw-buttons">
          <div className="button" onClick={() => setWithdrawModalVisible(true)}>
            Withdraw
          </div>
          <div className="button" onClick={withdrawAll}>
            Withdraw ALL
          </div>
        </div>
      </CardStakeView>
      <StakeModal refresh={refresh} visible={stakeModalVisible} closeModal={() => setStakeModalVisible(false)} />
      <WithdrawModal
        refresh={refresh}
        visible={withdrawModalVisible}
        closeModal={() => setWithdrawModalVisible(false)}
      />
    </>
  );
});

const CardStakeView = styled.div`
  width: 420px;
  color: #212121;
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  border: 2px solid #212121;
  margin-bottom: 20px;
  margin-right: 20px;
  .info-row {
    width: 100%;
    display: flex;
    align-items: center;
    .logo-wrapper {
      height: 30px;
      width: 30px;
      object-fit: cover;
      background: #212121;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .token-name {
      font-size: 18px;
      margin: 0;
    }
  }
  .profit-row {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-item {
      flex: 1;
      border-radius: 24px;
      margin-right: 14px;
      padding: 8px 12px;
      border: 2px solid #212121;
      &:last-child {
        margin-right: 0;
      }
      .label {
        margin-bottom: 6px;
        font-size: 12px;
      }
      .value {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }
  .button {
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 13px;
    text-align: center;
    border: 2px solid #212121;
    background: #ffffff;
    transition: all 0.3s ease-in;
    cursor: pointer;
    &:hover {
      background: #d9d9d9;
    }
  }
  .earned-row,
  .stake-token {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-panel {
      font-size: 13px;
      > span {
        display: block;
      }
      .label-title {
        margin-bottom: 4px;
      }
    }
  }
  .withdraw-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      flex: 1;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default CardStake;
