import React, { useEffect, useState } from 'react';
import VaultDashboard from '@/components/MyVault/VaultDashboard';
import Tabs from '@/components/Tab';
import { allDepositStrategyList } from '@/config/allLpTokens';
import { useWeb3 } from '@/web3';
import { injectContractAppController } from '@/contracts';
import { Strategy } from '@/config/types';
import styles from './style.module.scss';
import InnovationDepositTable from './components/InnovationDepositTable';
import CommonDepositTable from './components/CommonDepositTable';
import ArchivedDepositTable from './components/ArchivedDepositTable';

const DepositList: React.FC = () => {
  const { contractContainer } = useWeb3();
  const [archivedList, setArchivedList] = useState<Strategy[]>([]);
  const [commonList, setCommonList] = useState<Strategy[]>([]);

  useEffect(() => {
    if (!contractContainer) return;
    const contract = injectContractAppController(contractContainer);
    const promises: Promise<any>[] = allDepositStrategyList.map(each => contract.vaultStates(each.vaultAddress));
    Promise.all(promises).then(vaultStates => {
      const archivedDepositStrategies = allDepositStrategyList.filter(
        (strategy, i) => vaultStates[i][1] === false && vaultStates[i][3] === false && vaultStates[i][4] === false,
      );
      setArchivedList(archivedDepositStrategies);
      const commonDepositStrategies = allDepositStrategyList.filter(
        (strategy, i) => !(vaultStates[i][1] === false && vaultStates[i][3] === false && vaultStates[i][4] === false),
      );
      setCommonList(commonDepositStrategies);
    });
  }, [contractContainer]);

  return (
    <div>
      <section className={styles.DepositListWrapper}>
        <VaultDashboard />
        <section className={styles.TableArea}>
          <Tabs
            tabs={[
              {
                key: 'Pro',
                label: 'Pro',
                content: <CommonDepositTable list={commonList} />,
              },
              {
                key: 'Archived',
                label: 'Archived',
                content: <ArchivedDepositTable list={archivedList} />,
              },
            ]}
          />
        </section>
      </section>
    </div>
  );
};
export default DepositList;
