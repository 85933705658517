import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';
import { pathToRegexp } from 'path-to-regexp';
import { Popover, Tooltip } from 'antd';
import menus from '../../menus';
import otherMenus from './otherMenus';
import styles from './style.module.scss';
import { DuetLogo } from '../DuetLogo';
import NewBadge from '../NewBadge';

const LeftNavigator: React.FC = () => {
  const location = useLocation();

  const currentMenu = menus.find(menu => {
    if (menu.outerLink) return false;
    if (pathToRegexp(menu.link).test(location.pathname)) {
      return true;
    }
    if (menu.children) {
      return menu.children.some(child => pathToRegexp(child.route).test(location.pathname));
    }
    return false;
  });

  const renderMenuChildren = (children: { link: string; icon: string }[]) => {
    return (
      <section className={styles.SubMenu}>
        {children.map(menu => {
          return (
            <a key={menu.link} className={styles.subMenuItem} href={menu.link} target="_blank" rel="noreferrer">
              <i className={classNames('iconfont', menu.icon, styles.iconfont)} />
            </a>
          );
        })}
      </section>
    );
  };

  const renderDuetV1 = () => {
    return (
      <div style={{ backgroundColor: '#0f0b2b' }}>
        {menus
          .filter(menu => !menu.hideInMenu)
          .map((menu, index) => {
            if (menu.outerLink) {
              return (
                <a
                  href={menu.link}
                  target="_blank"
                  key={menu.text}
                  className={classNames(styles.menuItem, {
                    [styles.active]: currentMenu?.link === menu.link,
                  })}
                  rel="noreferrer">
                  <i className={classNames('iconfont', menu.icon, styles.i)} />
                  <span className={styles.menuText}>
                    {menu.text}
                    {menu.isNew && (
                      <NewWrapper>
                        <NewBadge />
                      </NewWrapper>
                    )}
                  </span>
                </a>
              );
            }
            return (
              <Tooltip key={index} title={menu.description} placement="right">
                <Link
                  to={menu.link}
                  key={menu.text}
                  className={classNames(styles.menuItem, {
                    [styles.active]: currentMenu?.link === menu.link,
                  })}>
                  <i className={classNames('iconfont', menu.icon, styles.i)} />
                  <span className={styles.menuText}>
                    {menu.text}
                    {menu.isNew && (
                      <NewWrapper>
                        <NewBadge />
                      </NewWrapper>
                    )}
                  </span>
                </Link>
              </Tooltip>
            );
          })}
      </div>
    );
  };

  return (
    <section className={styles.LeftNavigatorWrapper}>
      <div className={styles.topMenu}>
        <DuetLogo className={styles.duetLogo} />
        <a className={styles.menuItem} href="https://pro.duet.finance" target="_blank" rel="noreferrer">
          <i className={classNames('iconfont', 'icon-trade', styles.i)} />
          <span className={styles.menuText}>Duet V2</span>
        </a>
        <Popover
          className={styles.test}
          getPopupContainer={trigger => trigger.parentElement!}
          content={renderDuetV1()}
          trigger="hover"
          placement="right"
          overlayClassName="popover-no-arrow">
          <span className={classNames(styles.menuItem, styles.active)}>
            <i className={classNames('iconfont', `icon-ccbox`, styles.i)} />
            <span className={styles.menuText}>Duet V1</span>
          </span>
        </Popover>
        <a className={styles.menuItem} href="https://bonds.duet.finance/" target="_blank" rel="noreferrer">
          <i className={classNames('iconfont', 'icon-bonds', styles.i)} />
          <span className={styles.menuText}>Duet Bonds</span>
        </a>
        <a className={styles.menuItem} href="/duet-pro-cloud" target="_blank" rel="noreferrer">
          <i className={classNames('iconfont', 'icon-duet-pro-cloud', styles.i)} />
          <span className={styles.menuText}>Pro Cloud</span>
        </a>
      </div>

      <div className={styles.belowMenu}>
        {otherMenus.map(menu => {
          if (menu.link) {
            return (
              <a target="_blank" href={menu.link} key={menu.text} className={styles.menuItem} rel="noreferrer">
                <i className={classNames('iconfont', menu.icon, styles.i)} />
                <span className={styles.menuText}>{menu.text}</span>
              </a>
            );
          }
          if (menu.children) {
            return (
              <Popover
                className={styles.test}
                getPopupContainer={trigger => trigger.parentElement!}
                content={renderMenuChildren(menu.children)}
                trigger="hover"
                placement="right"
                overlayClassName="popover-no-arrow"
                key={menu.text}>
                <span key={menu.text} className={styles.menuItem}>
                  <i className={classNames('iconfont', menu.icon, styles.i)} />
                  <span className={styles.menuText}>{menu.text}</span>
                </span>
              </Popover>
            );
          }
          return null;
        })}
      </div>

      {/* <AuditReport href={URLS_MAP.auditReport} target="_blank">
        <div className="icon-div">
          <i className="iconfont icon-BlockSec" />
        </div>
        <span className="audit-text">Audit Report</span>
      </AuditReport> */}
    </section>
  );
};

export default LeftNavigator;

const LeftNavigatorWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20px;
  width: 80px;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(1deg, #120e2b 0%, #0f0930 71%, #311b69 100%);
  z-index: 9;

  .top-menu {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .below-menu {
  }

  .duet-logo {
    width: 48px;
    height: 67px;
    margin-bottom: 25px;
  }

  .menu-item {
    display: inline-block;
    padding-top: 14px;
    font-size: 15px;
    font-weight: bold;
    color: #d7d6d6;
    width: 80px;
    height: 72px;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 5px;
    }

    i {
      display: inline-block;
      color: #d7d6d6;
      font-size: 22px;
      line-height: 22px;
    }

    .menu-text {
      margin-top: 5px;
      font-family: inter;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  .active {
    background: #241963;

    span.menu-text {
      color: #d7d6d6;
    }
  }

  .below-menu {
    i {
      font-size: 24px;
    }

    .menu-text {
      margin-top: 5px;
      font-family: inter;
      font-weight: 400;
      white-space: nowrap;
      font-size: 12px;
    }

    .menu-item {
      color: #9195a5;
      i {
        color: #9195a5;
      }
    }

    .menu-item:hover {
      color: white;

      i {
        color: white;
      }
    }
  }
`;

const AuditReport = styled.a`
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .icon-div {
    width: 24px;
    height: 24px;
    background: #707482;
    border-radius: 50%;
    text-align: center;

    .iconfont {
      font-size: 18px;
      line-height: 24px;
      color: #120f25;
    }
  }

  .audit-text {
    font-size: 14px;
    font-weight: bold;
    color: #707482;
    line-height: 16px;
    margin-top: 5px;
    text-align: center;
  }
`;

const SubMenu = styled.div`
  height: 80px;
  background: #100b2d;
  box-shadow: 0px 10px 20px 0px rgba(97, 59, 244, 0.2);
  border-radius: 4px;
  padding-top: 24px;
  padding-right: 20px;

  .sub-menu-item {
    width: 24px;
    height: 24px;
    color: #9195a5;
    padding-left: 20px;

    .iconfont {
      font-size: 32px;
    }

    &:hover {
      color: white;
    }
  }
`;

const NewWrapper = styled.div`
  position: absolute;
  right: -15px;
  top: 5px;
`;
